import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.js";
import { Select } from "clutch/src/Select/Select.jsx";
import TextInput from "clutch/src/TextInput/TextInput.jsx";
import { t as gt } from "i18next";
import type { PickKeys } from "ts-essentials";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { ElementKind } from "@/game-palworld/constants/elements.mjs";
import { ELEMENTS } from "@/game-palworld/constants/elements.mjs";
import { WORK_SUITABILITIES } from "@/game-palworld/constants/work-suitabilities.mjs";
import type { Pal } from "@/game-palworld/models/model-wiki.mjs";
import { formatPaldeckId } from "@/game-palworld/utils/format-paldeck-id.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import Container from "@/shared/ContentContainer.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageHeader from "@/shared/PageHeader.jsx";
import rangeBucket from "@/util/range-bucket.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";

const Styled = {
  tierListCss: () => css`
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);

    .search-bar {
      label {
        max-width: 180px;
      }
    }

    .tier {
      display: grid;
      grid-template-columns: min-content 1fr;
      gap: var(--sp-1);

      .icon-container {
        padding-top: var(--sp-3);
      }

      .tier-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, 150px);

        a {
          border-radius: var(--br);
          width: 100%;
          padding: var(--sp-3) var(--sp-4);

          .pal-portrait {
            position: relative;
            border-radius: var(--br);

            background: linear-gradient(
              to right,
              var(--element-primary) 33%,
              var(--element-secondary) 66%
            );

            width: calc(var(--img-w) + 4px);
            height: calc(var(--img-w) + 4px);

            &:before {
              content: "";
              border-radius: var(--br);
              position: absolute;
              inset: 1px;
              width: calc(var(--img-w) + 1px);
              height: calc(var(--img-w) + 1px);
              background: var(--card-surface, var(--shade8));
            }

            img {
              border-radius: var(--br);
              position: absolute;
              top: 1px;
              left: 1px;
            }
          }

          &:hover {
            background: var(--shade6);

            .pal-portrait:before {
              background: var(--shade6);
            }
          }
        }
      }
    }
  `,
  tooltipCss: () => css`
    width: 200px;

    .work {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(33px, 1fr));
      gap: var(--sp-1);
    }

    & > *:not(:last-child) {
      border-bottom: 1px solid var(--shade3-25);
    }
  `,
};

// weight towards higher levels in skills
const workScoreWeight = [0, 1, 2, 4, 6];
const getWorkScore = (p: Pal) =>
  Object.entries(p.workSutiability).reduce(
    (sum, [key, value]) =>
      WORK_SUITABILITIES[key] ? sum + workScoreWeight[value] : sum,
    0,
  );

const palStats: PickKeys<Pal, number>[] = [
  "hp",
  "defense",
  "meleeAttack",
  "shotAttack",
  "support",
];
// TODO: take passive & partner skill into account
const rarityMult = 10;
const getStatScore = (p: Pal) =>
  palStats.reduce((sum, stat) => sum + p[stat], p.rarity * rarityMult);

type ListType = {
  scoreFn: (p: Pal) => number;
  tierBuckets: Record<number, string>;
  filterFn: (p: Pal) => boolean;
};

const jumpMult = 10;
const workRangeBucket = rangeBucket([
  [0, "D"],
  [1, "C"],
  [2, "B"],
  [3, "A"],
  [4, "S"],
]);

const elementFilter = (p: Pal, e: string) =>
  p.elements.includes(e as ElementKind);

const listOptions: {
  key: string;
  title: Translation;
  filters: Record<string, (p: Pal, filter: string) => boolean>;
  getList: (filters: {
    element: string;
    work: string;
    mount: string;
  }) => string;
  lists: Record<string, ListType>;
}[] = [
  {
    key: "combat",
    title: ["palworld:tierlist.combat", "Best Combat Pals"] as Translation,
    filters: { element: elementFilter },
    getList: () => "all",
    lists: {
      all: {
        scoreFn: getStatScore,
        tierBuckets: rangeBucket([
          [0, "D"],
          [395, "C"],
          [550, "B"],
          [585, "A"],
          [680, "S"],
        ]),
        filterFn: null,
      },
    },
  },
  {
    key: "base",
    title: ["palworld:tierlist.base", "Best Worker Pals"] as Translation,
    filters: { element: elementFilter, work: () => true },
    getList: ({ work }) => work,
    lists: {
      all: {
        scoreFn: getWorkScore,
        tierBuckets: rangeBucket([
          [0, "D"],
          [3, "C"],
          [6, "B"],
          [7, "A"],
          [11, "S"],
        ]),
        filterFn: null,
      },
      ...Object.entries(WORK_SUITABILITIES).reduce((acc, [key]) => {
        acc[key] = {
          scoreFn: (p: Pal) => p.workSutiability[key],
          filterFn: (p: Pal) => p.workSutiability[key],
          tierBuckets: workRangeBucket,
        };
        return acc;
      }, {}),
    },
  },
  {
    key: "mount",
    title: ["palworld:tierlist.mounts", "Best Mounts"] as Translation,
    filters: { element: elementFilter, mount: () => true },
    getList: ({ mount }) => mount,
    lists: {
      flying: {
        scoreFn: (p: Pal) => (p.isMount && p.mountData?.speed) || 0,
        tierBuckets: rangeBucket([
          [0, "D"],
          [1100, "C"],
          [1200, "B"],
          [1400, "A"],
          [3000, "S"],
        ]),
        filterFn: (p: Pal) => p.isMount && p.mountData?.type === "Flying",
      },
      ground: {
        scoreFn: (p: Pal) =>
          p.isMount ? p.mountData?.speed + p.mountData?.jumps * jumpMult : 0,
        tierBuckets: rangeBucket([
          [0, "D"],
          [600, "C"],
          [800, "B"],
          [1050, "A"],
          [1300, "S"],
        ]),
        filterFn: (p: Pal) => p.isMount && p.mountData?.type === "Ground",
      },
      water: {
        scoreFn: (p: Pal) => (p.isMount && p.mountData?.speed) || 0,
        tierBuckets: rangeBucket([
          [0, "D"],
          [3, "C"],
          [6, "B"],
          [7, "A"],
          [11, "S"],
        ]),
        filterFn: (p: Pal) => p.isMount && p.mountData?.type === "Water",
      },
    },
  },
];

const workOptions = Object.entries(WORK_SUITABILITIES).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: v.src });
    return acc;
  },
  [
    {
      value: "all",
      text: ["palworld:work.all", "All Work Skills"] as Translation,
      image: "",
    },
  ],
);

const elementOptions = Object.entries(ELEMENTS).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: v.imageSrc });
    return acc;
  },
  [
    {
      value: "all",
      text: ["palworld:element.all", "All Elements"] as Translation,
      image: "",
    },
  ],
);

const mountOptions = [
  {
    value: "ground",
    text: ["palworld:mounts.ground", "Ground"] as Translation,
    image: "",
  },
  {
    value: "flying",
    text: ["palworld:mounts.flying", "Flying"] as Translation,
    image: "",
  },
  {
    value: "water",
    text: ["palworld:mounts.water", "Water"] as Translation,
    image: "",
  },
];

const TierListInner = ({ tiers }: { tiers: Record<Tier, Pal[]> }) => {
  const { t } = useTranslation();
  if (!Object.values(tiers).some((t) => t.length))
    return (
      <Card className="flex justify-center">
        <span className="type-caption shade0">
          {t("common:noResults", "No results")}
        </span>
      </Card>
    );

  return (
    <Card>
      <ol className="flex column gap-3">
        {Object.entries(tiers).map(([key, items]) => {
          const Icon = getTierIcon(key);
          return (
            <li key={key} className="tier">
              <div className="icon-container">
                <img src={Icon} width="28" height="28" />
              </div>
              <ol className="tier-items">
                {items.map((p) => (
                  <PalItem key={p.id} {...p} />
                ))}
              </ol>
            </li>
          );
        })}
      </ol>
    </Card>
  );
};

type Tier = "S" | "A" | "B" | "C" | "D";
const baseUri = "/palworld/tier-list";
function TierList() {
  const { t } = useTranslation();
  const {
    parameters: [listKey],
  } = useRoute();
  const { pals } = useWikiData();
  const listType =
    listOptions.find(({ key }) => key === listKey) ?? listOptions[0];

  const [searchText, setSearch] = useQuery<string>("search", "");
  const [elementFilter, setElement] = useQuery<string>("element", "all");
  const [workFilter, setWork] = useQuery<string>("work", "all");
  const [mountFilter, setMount] = useQuery<string>("mount", "ground");

  const list =
    listType.lists[
      listType.getList({
        element: elementFilter,
        work: workFilter,
        mount: mountFilter,
      })
    ];
  const tiers = useMemo<Record<Tier, Pal[]>>(() => {
    if (!pals) return {} as Record<Tier, Pal[]>;
    const { filterFn, scoreFn, tierBuckets } = list;
    const palList = filterFn
      ? Object.values(pals).filter(filterFn)
      : Object.values(pals);
    return palList
      .map((p: Pal) => [p, scoreFn(p)] as const)
      .sort((a, b) => b[1] - a[1])
      .reduce(
        (acc, [p, score]) => {
          const tier = (acc[tierBuckets[score]] ||= []);
          tier.push(p);
          return acc;
        },
        {} as Record<Tier, Pal[]>,
      );
  }, [list, pals]);

  const filterMap = listType.filters;
  const filteredTiers = useMemo(() => {
    const filteredTiers = {} as Record<Tier, Pal[]>;
    for (const [tier, pals] of Object.entries(tiers)) {
      const fp = pals.filter((p) => {
        return (
          (!searchText ||
            t(...p.label)
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())) &&
          (!filterMap.element ||
            elementFilter === "all" ||
            filterMap.element(p, elementFilter))
        );
      });
      if (fp.length) filteredTiers[tier] = fp;
    }
    return filteredTiers;
  }, [elementFilter, filterMap, searchText, t, tiers]);

  return (
    <Container className={Styled.tierListCss()}>
      <PageHeader
        title={t("common:navigation.tierList", "Tier List")}
        links={listOptions.map(({ key, title }) => ({
          text: title,
          url: `${baseUri}/${key}`,
        }))}
      />
      <div className="flex gap-2 wrap search-bar">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setSearch(e.target.value)}
          value={searchText}
        />
        {!!listType.filters.element && (
          <Select
            options={elementOptions}
            selected={elementFilter}
            onChange={(v) => setElement(v)}
          />
        )}
        {!!listType.filters.work && (
          <Select
            options={workOptions}
            selected={workFilter}
            onChange={(v) => setWork(v)}
          />
        )}
        {!!listType.filters.mount && (
          <Select
            options={mountOptions}
            selected={mountFilter}
            onChange={(v) => setMount(v)}
          />
        )}
      </div>
      {pals ? (
        <TierListInner tiers={filteredTiers} />
      ) : (
        <Card loading style={{ height: "600px" }} />
      )}
    </Container>
  );
}

const PalInfoTooltip = ({
  label,
  elements,
  hp,
  shotAttack: attack,
  defense,
  workSutiability,
  partnerSkill,
  paldeckId,
  paldeckIdSuffix,
  isMount,
  mountData,
}: Pal) => {
  const { t } = useTranslation();

  return (
    <div className={Styled.tooltipCss()}>
      <div className="flex gap-1 align-center justify-between">
        <div className="flex gap-1">
          <h5 className="type-subtitle--bold">{t(...label)}</h5>
          <span className="type-subtitle shade1">
            {formatPaldeckId(paldeckId, paldeckIdSuffix)}
          </span>
        </div>
        <div className="flex">
          {elements.map((e) => (
            <img key={e} width={15} height={15} src={ELEMENTS[e].iconSrc} />
          ))}
        </div>
      </div>
      <ul className="type-caption flex gap-1 justify-between stats">
        <li>{t("palworld:aagHP", "HP: {{hp, number}}", { hp })}</li>
        <li>
          {t("palworld:aagAttack", "ATK: {{attack, number}}", { attack })}
        </li>
        <li>
          {t("palworld:aagDefense", "DEF: {{defense, number}}", { defense })}
        </li>
      </ul>
      <ul className="type-caption work">
        {Object.entries(workSutiability).map(
          ([key, value]) =>
            Boolean(WORK_SUITABILITIES[key]) && (
              <div key={key} className="flex align-center">
                <img width={25} height={25} src={WORK_SUITABILITIES[key].src} />
                {value}
              </div>
            ),
        )}
      </ul>
      <div>
        <h6 className="type-caption--bold">{t(...partnerSkill.name)}</h6>
        <p className="type-caption shade1">{t(...partnerSkill.description)}</p>
      </div>
      {isMount && (
        <ul className="type-caption flex gap-1 justify-between stats">
          <li>
            {t("palworld:aagSpeed", "SPD: {{speed, number}}", {
              speed: mountData.speed,
            })}
          </li>
          <li>
            {t("palworld:aagStamina", "STA: {{stamina, number}}", {
              stamina: mountData.stamina,
            })}
          </li>
          {mountData.type === "Ground" && (
            <li>
              {t("palworld:aagJump", "JUMP: {{jumps, number}}", {
                jumps: mountData.jumps || 1,
              })}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

const imgWidth = 46;
const PalItem = (pal: Pal) => {
  const { t } = useTranslation();

  const { id, elements, imageUri, label } = pal;
  return (
    <li>
      <a
        className="flex column gap-1 align-start"
        href={`/palworld/database/pals/${id}`}
        data-place="bottom"
        data-tip={renderToStaticMarkup(<PalInfoTooltip {...pal} />)}
      >
        <div
          className="pal-portrait"
          style={{
            "--element-primary": ELEMENTS[elements[0]]?.color || "",
            "--element-secondary":
              ELEMENTS[elements[1] || elements[0]]?.color || "",
            "--img-w": `${imgWidth}px`,
          }}
        >
          <img
            src={`${appURLs.CDN_PLAIN}/${imageUri}`}
            width={imgWidth}
            height={imgWidth}
            alt={t(...label)}
          />
        </div>
        <span className="pal-name type-subtitle--bold">{t(...label)}</span>
      </a>
    </li>
  );
};

export function meta([list]) {
  const listName = gt(
    ...(listOptions.find(({ key }) => key === list) ?? listOptions[0]).title,
  );
  return {
    title: [
      "palworld:tierlist.title",
      "Tier List of the Pals Palworld - {{listName}}",
      { listName },
    ] as Translation,
    description: [
      "palworld:tierlist.description",
      "Dive into the world of Palworld with our comprehensive Pal Tier List! Discover the best pals for every aspect of your adventure, from base building to intense combat to flying, ground or water mounts. Whether you're seeking the most powerful pals for dominating battles, the top-tier pals for efficient farming and mining, or the best pals for breeding, our guide has you covered. Uncover the secrets to assembling a formidable Paldeck with our expertly curated pal tier list, ensuring you have the right companions for every challenge Palworld throws your way. Explore now and become the ultimate Pal master!",
    ] as Translation,
    subtitle: !IS_APP,
  };
}

export default TierList;
